














  import Vue, {PropType} from "vue";
  import ExperienceItem from "../../components/items/ExperienceItem.vue";
  import { Experience } from "../../domain/Experience";
  import { Experiences } from "../../domain/Experiences";

  export default Vue.extend({
    name: 'ExperienceList',
    components: { ExperienceItem },
    props: {
      experiences: {
        type: Object as PropType<Experiences>,
        required : true
      },
    },
    methods: {
      handleSuggestionClick( suggestion: Experience ): void {
        this.$emit('click', suggestion );
      }
    }
  });
