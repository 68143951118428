var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "review-filter-items" },
    _vm._l(_vm.reviewItems, function (reviewItem) {
      return _c(
        "li",
        {
          staticClass: "review-filter-item",
          on: {
            click: function ($event) {
              return _vm.handleReviewFilterItemClick(reviewItem)
            },
          },
        },
        [
          _c("i", {
            staticClass: "sd2-icons review-filter-icon",
            class: reviewItem.isSelected
              ? "active radio-button-checked"
              : "radio-button",
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml-2" },
            [
              reviewItem.key > 0
                ? _vm._l(_vm.stars, function (i) {
                    return _c("span", [
                      _c("i", {
                        staticClass: "sd2-icons",
                        class:
                          i <= reviewItem.key
                            ? "star filled-star"
                            : "star-border empty-star",
                      }),
                    ])
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "review-filter-item-description",
                  class: {
                    active: reviewItem.isSelected,
                    "ml-2": reviewItem.key > 0,
                  },
                },
                [_vm._v(_vm._s(reviewItem.label) + "\n      ")]
              ),
            ],
            2
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }