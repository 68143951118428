import ValueObject from "../../../../../frontend/ValueObject";
import {filter, uniq} from "@s-libs/micro-dash";
import {Arrangement} from "../../../../Deals/view/vue/domain/Arrangement";
import {ArrangementOption} from "../../../../Deals/view/vue/domain/ArrangementOption";
import {AmountSelect} from "./AmountSelect";
import {Price} from "../../../../Deals/view/vue/domain/Price";
import {defaultCurrencyDTO} from "../../../../Deals/view/vue/domain/dto/CurrencyDTO";

export interface IAvailability {
    alerts?: any,
    add_to_cart_button: any,
    arrangements: any[],
    arrangement_options: any[],
    arrangement_display_mode: string,
    tabs: any[],
    calendar_flow: string,
    calendar_unique: string,
    is_time_sensitive: boolean,
    options: any,
    unique: string,
    button: {
        caption: string
    },
    labels: {
        call_to_action: string
    },
    deal_prices?: any;
}

export class HotelsAvailability extends ValueObject<IAvailability> {

    set alerts(alerts: any) {
        this._props.alerts = alerts;
    }

    set additionalAmount(amount: AmountSelect) {
        this._props.options.additional_amount = amount;
    }

    set dealPrices(deal_prices: any) {
        this._props.deal_prices = deal_prices;
    }

    get dealPrices() {
        return this._props.deal_prices;
    }

    get alerts() {
        return this._props.alerts;
    }

    get tabs() {
        return this._props.tabs;
    }

    get calendarFlow() {
        return this._props.calendar_flow;
    }

    get calendarUnique() {
        return this._props.calendar_unique;
    }

    get unique() {
        return this._props.unique;
    }

    get arrangements(): any {
        return this._props.arrangements ?? [];
    }

    get arrangementOptions(): any {
        return this._props.arrangement_options ?? [];
    }

    get arrangementDisplayType(): string {
        return this._props.arrangement_display_mode;
    }

    get amount(): AmountSelect {
        return this._props.options.amount;
    }

    get additionalAmount(): AmountSelect {
        return this._props.options.additional_amount;
    }

    get soldOutAlert(): any {
        return this._props.alerts.sold_out;
    }

    get labels(): any {
        return this._props.labels;
    }

    get button(): any {
        return this._props.button;
    }

    get isTimeSensitive(): boolean {
        return this._props.is_time_sensitive;
    }

    public static create(props: any) {
        return new HotelsAvailability(props);
    }

    private static fetchTabs(arrangements: any[]) {
        return filter(uniq(arrangements.map((arrangement: any) => arrangement.multi_tab_name)), (tabName) => tabName !== '');
    }

    public static fromApi(props: any) {
        const amount = AmountSelect.create(props.options.amount);
        const availability = HotelsAvailability.create({
            arrangements: props.arrangements.map((arrangement: any) => Arrangement.create(arrangement)),
            arrangement_options: props.arrangement_options ? props.arrangement_options.map((arrangementOption: any) => ArrangementOption.create(arrangementOption)) : null,
            arrangement_display_mode: props.arrangement_display_mode ?? 'normal',
            calendar_flow: props.calendar_flow,
            calendar_unique: props.calendar_unique,
            is_time_sensitive: props.is_time_sensitive,
            tabs: this.fetchTabs(props.arrangements),
            options: {amount: amount},
            button: props.button,
            labels: props.labels ?? '',
            unique: props.unique,
        });
        if (props.deal_prices) {
            availability.dealPrices = {
                discount_label: props.deal_prices.discount_label,
                from_price: Price.create({
                    amount: props.deal_prices.from_price != null ? props.deal_prices.from_price.amount / 100 : 0,
                    currency: props.deal_prices.from_price?.currency ?? defaultCurrencyDTO,
                }),
                price: Price.create({
                    amount: props.deal_prices.price !== null ? props.deal_prices.price.amount / 100 : 0,
                    currency: props.deal_prices.price?.currency ?? defaultCurrencyDTO,
                }),
                priceLabel: props.deal_prices.price_label
            };
        }

        if (props.alerts) {
            availability.alerts = props.alerts;
        }

        if (props.options.additional_amount) {
            availability.additionalAmount = AmountSelect.create(props.options.additional_amount);
        }

        return availability;
    }
}
