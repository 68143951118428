import {Price} from "./Price";

export class Money {
    private readonly price: Price;
    private readonly language: string;

    public constructor(price: Price, language: string) {
        this.price = price;
        this.language = language.replace('_', '-');

    }

    public toString(allowHTML: boolean = true): string {
        let localCurrency: LocalizedCurrency = this.getLocalizedCurrency();
        if (localCurrency.fraction === '00') {
            return this.withNoCents();
        }
        let fullPrice: string =
            localCurrency.base
            + (allowHTML ? '<span>' : '')
            + localCurrency.decimalSeparator + localCurrency.fraction
            + (allowHTML ? '</span>' : '');

        if (localCurrency.symbolLocation === 'begin') {
            return localCurrency.symbol + fullPrice;
        }
        return fullPrice + localCurrency.symbol;
    }

    public withSmallCents(allowHTML: boolean = true): string {
        let localCurrency: LocalizedCurrency = this.getLocalizedCurrency();
        if (localCurrency.fraction === '00') {
            return this.withNoCents();
        }
        let fullPrice: string =
            localCurrency.base
            + (allowHTML ? '<sub>' : '')
            + localCurrency.decimalSeparator + localCurrency.fraction
            + (allowHTML ? '</sub>' : '');
        if (localCurrency.symbolLocation === 'begin') {
            return localCurrency.symbol + fullPrice;
        }
        return fullPrice + localCurrency.symbol;
    }

    public withNoCents(): string {
        let localCurrency: LocalizedCurrency = this.getLocalizedCurrency();
        if (localCurrency.symbolLocation === 'begin') {
            return localCurrency.symbol + localCurrency.base;
        }
        return localCurrency.base + localCurrency.symbol;
    }

    private getLocalizedCurrency(): LocalizedCurrency {
        let intl: Intl.NumberFormat = new Intl.NumberFormat(
            this.language,
            {
                'style': 'currency',
                'currency': this.price.currency.code,
                'maximumFractionDigits': 2,
                'minimumFractionDigits': 2
            }
        );
        return new LocalizedCurrency(intl.formatToParts(this.price.amount));
    }
}

class LocalizedCurrency {
    public readonly symbol: string;
    public readonly symbolLocation: string;
    public readonly decimalSeparator: string;
    public readonly base: string = '';
    public readonly fraction: string = '00';

    public constructor(parts: Array<any>) {
        for (let i: number = 0; i < parts.length; i++) {
            let val = parts[i].value;
            switch (parts[i].type) {
                case 'currency':
                    this.symbolLocation = (i === 0) ? 'begin' : 'end';
                    this.symbol = val;
                    break;
                case 'integer':
                case 'group':
                    this.base += val;
                    break;
                case 'decimal':
                    this.decimalSeparator = val;
                    break;
                case 'fraction':
                    this.fraction = val;
                    break;
            }
        }
    }
}