


















































































































import Vue, {PropType} from "vue";
import SortList from "./SortListFilter.vue";
import BottomDrawer from "../BottomDrawer.vue";
import {
  Badge,
  CategoryItem,
  DistanceFilterItem,
  FilterFlow,
  FilterModalType,
  GetFilterResponse,
  MappedDistanceFilterItem,
  NearbySearchLocation,
  ReviewFilterItem,
  SearchFilterEmit, SortListEmit,
  SortListItem
} from "../../types/FilterTypes";
import CategoryFilter, {OnCategoryClicked} from "./CategoryFilter.vue";
import DistanceFilter from "./DistanceFilter.vue";
import PriceSlider, {PriceSliderEmit} from "./PriceSliderFilter.vue";
import CitySelect from "../../../module/SearchBar/view/vue/components/CitySelect.vue";
import ReviewFilter from "./ReviewFilter.vue";
import FilterService from "../../services/FilterService";
import DealList from "../../../module/Deals/view/vue/components/lists/DealList.vue";
import {cloneDeep, debounce} from "@s-libs/micro-dash";
import {ICity} from "../../../module/SearchBar/view/vue/domain/City";
import Message from "../../../module/Deals/view/vue/components/Message.vue";
import Loader from "../Loader.vue";
import SearchLocation from "./SearchLocation.vue";
import {getUrlQueryParam} from "../../helper/urlParams";
import axios, {CancelTokenSource} from "axios";

interface IProps {
  cityUnique: string;
  badge: Badge;
  filterModalType: FilterModalType;
  nearbySearchBar: string;
  showBadge: boolean;
}
export default Vue.extend({
  name: 'FilterModal',
  components: {
    SearchLocation,
    Loader,
    Message,
    DealList,
    ReviewFilter,
    CitySelect,
    PriceSlider,
    CategoryFilter,
    SortList,
    DistanceFilter,
    BottomDrawer
  },
  props: {
    cityUnique   : {
      type     : String,
      required : false
    },
    badge   : {
      type     : Object as PropType<IProps['badge']>,
      required : false
    },
    showBadge : {
      type : Boolean as PropType<IProps['showBadge']>,
      required : true
    },
    filterModalType: {
      type     : String as PropType<IProps['filterModalType']>,
      required : true
    },
    nearbySearchbar: {
      type: String as PropType<IProps['nearbySearchBar']>,
      required: false
    }
  },
  data(): any {
    return {
      isDistanceOptionsOpen:false,
      loadState: {showLoader: false, hideContent:false},
      labels: {},
      showFilterModal: false,
      drawerOrModalActive: false,
      parentTopPosition: null,
      loadCitySelect: false,
      visibleCitySelect:false,
      categoryRenderKey: 0,
      sortListRenderKey: 0,
      ratingListRenderKey: 0,
      cancelTokenSource: null,
      filter: {
        badge: {
          color:'',
          label:0
        },
        result_count: 0,
        sections: {
          categories: {
            options: [],
            select_all_key: '',
            selected: ['']
          },
          distance: {
            label:  '',
            latitude: 0,
            longitude: 0,
            location: '',
            options: [],
            selected: 'all'
          },
          price_range: {
            currency: {
              symbol: '',
              code: ''
            },
            max_value: 0,
            min_value: 0,
            selected: {
              min: null,
              max: null
            },
            step_size: 0
          },
          rating: {
            selected: 0,
            options: []
          },
          sorting: {
            selected: '',
            options: []
          }
        }
      },
      filteredDeals: null,
      currentFilterCriteria: null,
      activeFilterCriteria: null,
      cityToRestore: {},
      acceptedFilters : [ 'persisted', 'sorting', 'categories', 'price_range[min]', 'price_range[max]', 'distance', 'distance[location]', 'distance[value]', 'distance[lat]', 'distance[lng]', 'rating' ],
      nearbyLocationHasChanged: false,
      isCitySelected:false,
      nearbyLocation: {}
    }
  },
  computed : {
    FilterModalType() {
      return FilterModalType
    },
    FilterFlow() {
      return FilterFlow
    },
    showEmptyFilterState(): boolean {
      return this.$data.filteredDeals !== null && this.$data.filteredDeals.length === 0;
    },
    allDealsCategoryIsActive(): boolean {
      return this.$data.filter.sections.categories.selected.includes(this.filter.sections.categories.select_all_key)
    },
    showDrawerOrModal(): boolean {
      return (this.loadFilterModal || this.isMobile);
    },
    showCategoryFilter() {
      return this.$data.filter.sections.categories.options.length > 0
    },
    isMobile(): boolean {
     try {
       return sdViewport.isMobile();
     } catch {
        return false;
     }
    },
    filterResultsTranslation(): string {
      if(this.filter.sections.distance.label && this.filter.result_count == null) {
        return this.$t('36454.App_FilterLabelButtonResultsEmpty');
      }
      if (this.filter.result_count === 1) {
        return this.$t('36453.App_FilterLabelButtonResultsSingular')
      } else {
        const number = this.filter.result_count > 0 ? this.filter.result_count : 0;
        return this.$t('36114.App_FilterLabelButtonResults').replace(':num', number.toLocaleString( document.body.getAttribute('data-locale') ?? 'NL' ) );
      }
    },
    mappedDistanceFilterItems(): MappedDistanceFilterItem[] {
      const mappedDistanceFilterItems =  this.filter.sections.distance.options.map((distanceOptions:DistanceFilterItem) => {
        return {
          key: distanceOptions.key,
          value: distanceOptions.label
        }
      });
      return mappedDistanceFilterItems;
    },
    isFilterTypeHome(): boolean {
      return this.filterModalType === FilterModalType.Home;
    },
    isFilterTypeNearby(): boolean {
      return this.filterModalType === FilterModalType.Nearby;
    },
    nearbyLocationAsPoint(): string {
      return this.formattedNearbySearchBar.lat + ',' + this.formattedNearbySearchBar.long;
    },
    formattedNearbySearchBar():any {
      return this.nearbySearchbar ? JSON.parse(atob( this.nearbySearchbar )) as unknown as NearbySearchLocation : undefined
    },
    hasFiltersInSession():boolean {
      return this.filter.badge && parseInt(this.filter.badge.label) > 0;
    }
  },
  methods: {
    onFilterButtonClick() {
      this.$emit('on-filter-button-click');
      this.loadFilterModal();
    },
    closeSelf() {
      this.activeFilterCriteria = cloneDeep(this.currentFilterCriteria);
      this.showFilterModal = false;
      this.$emit('on-close-filter');
    },
    async resetFilterOutsideModal() {
      if(this.isFilterTypeHome) {
        this.activeFilterCriteria = await FilterService.getHomeFilterCriteriaByCityUnique(this.cityUnique, true);
      }
      if(this.isFilterTypeNearby) {
        this.activeFilterCriteria = await FilterService.getNearbyFilterCriteriaByPoint(this.nearbyLocationAsPoint, true);
      }
      this.filterOnActiveCriteria(FilterFlow.Home);
    },
    async resetFilter() {
      this.loadState.showLoader = true;
      if(this.isFilterTypeHome) {
        this.activeFilterCriteria = await FilterService.getHomeFilterCriteriaByCityUnique(this.cityUnique, true);
      } else {
        this.activeFilterCriteria = await FilterService.getNearbyFilterCriteriaByPoint(this.nearbyLocationAsPoint, true);
      }
      await this.handleFilterChange();
      this.loadState.showLoader = false;
    },
    showCitySelect() {
      this.loadCitySelect = true;
      setTimeout(() => {
        this.visibleCitySelect = true;
      },10);
    },
    closeCitySelect() {
      this.visibleCitySelect = false;
    },
    formatFilters(filters: GetFilterResponse): any {
      //region Price formatting
      const selectedMin = filters.sections.price_range.selected.min;
      const selectedMax = filters.sections.price_range.selected.max;

      if(filters.sections.price_range.min_value === filters.sections.price_range.max_value) {
        filters.sections.price_range.max_value += filters.sections.price_range.step_size;
      }
      if(selectedMin == undefined || selectedMin < filters.sections.price_range.min_value || selectedMin >= selectedMax) {
        filters.sections.price_range.selected.min = filters.sections.price_range.min_value;
      }
      if(selectedMax == undefined || selectedMax > filters.sections.price_range.max_value || selectedMax <= selectedMin) {
        filters.sections.price_range.selected.max = filters.sections.price_range.max_value;
      }

      //endregion
      //region Sorting
      filters.sections.sorting.options.forEach((sortItem) => {
        if(sortItem.key === filters.sections.sorting.selected) {
          sortItem.isSelected = true;
          return;
        }
      });
      //endregion
      //region Review
      filters.sections.rating.options.forEach((reviewItem) => {
        if(reviewItem.key === filters.sections.rating.selected) {
          reviewItem.isSelected = true;
          return;
        }
      });
      //endregion
      //region Category
      filters.sections.categories.options.forEach((categoryItem) => {
        if(filters.sections.categories.selected.includes(categoryItem.key)) {
          categoryItem.isSelected = true;
        }
      });
      filters.sections.categories.selected = (filters.sections.categories.selected as string).split(',')
      //endregion
      //region Distance
      if(this.isFilterTypeNearby && filters.sections.sorting.selected !== 'default' && filters.sections.distance.options[0].key === 'all') {
        filters.sections.distance.options.splice(0,1);
        if(filters.sections.distance.selected === 'all') {
          filters.sections.distance.selected = '15';
        }
      }
      if(Object.keys(this.cityToRestore).length > 0 && filters.sections.distance.selected !== 'all') {
        filters.sections.distance.label = this.cityToRestore.label;
        filters.sections.distance.latitude = this.cityToRestore.lat;
        filters.sections.distance.longitude = this.cityToRestore.lng;
        this.cityToRestore = {};
      }
      //endregion
      return filters;
    },
    async handleFilterChange() {
      let filters;
      if(this.isFilterTypeHome) {
        filters = await FilterService.getHomeFilterByCityUnique(this.cityUnique, this.getFilterQueryParam(this.activeFilterCriteria.filters), this.cancelTokenSource);
      } else {
        filters = await FilterService.getNearbyFilterByPoint(this.nearbyLocationAsPoint, this.getFilterQueryParam(this.activeFilterCriteria.filters), this.cancelTokenSource);
      }
      this.filter = this.formatFilters(filters);
    },
    async handleSortListItemClick(sortListEmit: SortListEmit) {
      this.activeFilterCriteria.filters.sorting = sortListEmit.toSelectedKey;
      this.filter.sections.sorting.options.forEach((sortItem:any) => {
        sortItem.isSelected = sortItem.key === sortListEmit.toSelectedKey;
      });
      this.reRenderSortList();
      this.debouncedHandleFilterChange();
      if(this.isFilterTypeNearby && sortListEmit.toSelectedKey !== 'default' && this.activeFilterCriteria.filters.distance === 'all') {
        if(this.filter.sections.distance.options[0].key === 'all') {
          this.filter.sections.distance.options.splice(0,1);
        }
        this.$refs.distanceFilter.$el.scrollIntoView({ behavior: 'smooth', inline: 'start' } );
        setTimeout(() => {
          this.isDistanceOptionsOpen = true;
          this.filter.sections.distance.selected = '15';
        },500);
      }
    },
    navigateToNearby() {
      let nearby;
      switch ((window as any).lang['currentLocale']) {
        case 'de':
          nearby = 'in-der-nahe';
          break;
        case 'fr':
          nearby = 'a-proximite';
          break;
        case 'en':
          nearby = 'nearby'
          break;
        case 'be':
        case 'nl':
        default:
          nearby = 'dichtbij';
          break;
      }
      const url = FilterService.removeDefaultValuesFromURL(this.getFilterQueryParam(this.activeFilterCriteria.filters));
      const slugifiedCity = this.activeFilterCriteria.filters.distance.location ? slugify(this.activeFilterCriteria.filters.distance.location) + '/' : ''
      window.location.assign(`/${nearby}/${slugifiedCity}?${ url }`);
    },
    selectCityOnNearby(isCity:boolean) {
      const $searchBar = $( '#search-bar' );
      const $searchableCityList = $searchBar.find('.searchableCityList');
      const locationName = this.formattedNearbySearchBar.placeholder;

      //if city is clicked
      if(!locationName) {
        $searchableCityList.find('.find-location').trigger('click')
        return;
      }

      if(isCity) {
        $searchableCityList.find(`[data-name="${locationName}"]:first`).trigger('click')
      } else {
        //if location is typed in searchbar
        const $trigger = $searchableCityList.find( 'ul li.trigger' );
        $trigger.data( 'name', locationName );
        $trigger.data( 'latitude', this.formattedNearbySearchBar.lat);
        $trigger.data( 'longitude', this.formattedNearbySearchBar.long );
        $trigger.data( 'resultCity', '' );
        $trigger.data( 'resultQuery', '' );
        $trigger.trigger( 'click' );
      }
    },
    async handleReviewItemClick(newReviewItem: ReviewFilterItem) {
      this.activeFilterCriteria.filters.rating = newReviewItem.key;
      this.filter.sections.rating.options.forEach((reviewItem:any) => {
          reviewItem.isSelected = reviewItem.key === newReviewItem.key;
      });
      this.reRenderRatingList();
      this.debouncedHandleFilterChange();
    },
    reRenderCategories() {
      this.categoryRenderKey += 1;
    },
    reRenderSortList() {
      this.sortListRenderKey += 1;
    },
    reRenderRatingList() {
      this.ratingListRenderKey += 1;
    },
    async handleCategoryClicked(payload: OnCategoryClicked) {
      const selectAllKey = this.filter.sections.categories.select_all_key;
      let selectedCategories: string[] = this.filter.sections.categories.selected;
      let scrollIntoView = true;
      let goToAllDeals = false;

      if(payload.categoryName === selectAllKey) {
        selectedCategories.splice(0, selectedCategories.length);
        selectedCategories.push(selectAllKey);
      }
      else if(!selectedCategories.includes(payload.categoryName)) {
        if(this.allDealsCategoryIsActive) {
          selectedCategories.splice(selectedCategories.indexOf(selectAllKey), 1);
        }
        selectedCategories.push(payload.categoryName);
      }
      else {
        scrollIntoView = false;
        selectedCategories.splice(selectedCategories.indexOf(payload.categoryName), 1);
        if (selectedCategories.length === 0) {
          selectedCategories.push(selectAllKey);
          scrollIntoView = true;
          goToAllDeals = true;
        }
      }

      this.activeFilterCriteria.filters.categories = this.filter.sections.categories.selected.join();
      this.debouncedHandleFilterChange();

      this.filter.sections.categories.options.forEach((category:CategoryItem) => {
        category.isSelected = selectedCategories.includes(category.key);
      })
      //set selected category active before the api
      this.reRenderCategories();

      if(scrollIntoView && payload.scrollCategoryIntoViewIfNeeded && typeof payload.scrollCategoryIntoViewIfNeeded === 'function') {
        let categoryName = goToAllDeals ? this.filter.sections.categories.select_all_key : payload.categoryName
        const scrollToIndex = this.filter.sections.categories.options.findIndex((category:CategoryItem) => category.key === categoryName );
        payload.scrollCategoryIntoViewIfNeeded(scrollToIndex);
      }
    },
    debounce : debounce( async ( callback: Function ) => {
          return callback();
        }, 500
    ),
    debouncedHandleFilterChange() {
      if(this.cancelTokenSource) {
        (this.cancelTokenSource as CancelTokenSource).cancel();
      }
      this.cancelTokenSource = axios.CancelToken.source();
      this.debounce(() => { this.handleFilterChange() });
    },
    handleSliderValuesChanged (payload: PriceSliderEmit) {
      this.filter.sections.price_range.selected = payload.selectedPrices;
      this.activeFilterCriteria.filters.price_range = payload.selectedPrices;

      if(payload.getNewFilters) {
        this.debouncedHandleFilterChange();
      }
    },
    toggleDistanceOptions(isDistanceOptionsOpen:boolean) {
      this.isDistanceOptionsOpen = isDistanceOptionsOpen;
    },
    onDistanceChanged(distance:any) {
      if(this.isFilterTypeHome) {
        this.activeFilterCriteria.filters.distance.value = distance.key;
      } else {
        this.activeFilterCriteria.filters.distance = distance.key;
      }

      this.filter.sections.distance.selected = distance.key;
      if(distance.key !== 'all' && Object.keys(this.cityToRestore).length > 0) {
        this.debouncedHandleFilterChange();
        return;
      }

      if( !this.filter.sections.distance.label && this.isFilterTypeHome) {
        navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
            this.activeFilterCriteria.filters.distance.lat = position.coords.latitude;
            this.activeFilterCriteria.filters.distance.lng = position.coords.longitude;
            this.debouncedHandleFilterChange();
        }, async () => {
          const title = (this as any).$t( 'gpsIsNotAllowedTitle' );
          const message = (this as any).$t( 'gpsIsNotAllowed' );
          const buttonOk = (this as any).$t( 'gpsIsNotAllowedButton' );
          const buttonBack = (this as any).$t( 'Back' );
          await this.$nextTick();
          filterAlertWithCallback( title,message, buttonOk,buttonBack, () => {
            this.showCitySelect(false);
          } );
        });
      } else {
        if(distance.key === 'all' && this.activeFilterCriteria.filters.distance.lat && this.activeFilterCriteria.filters.distance.lng ) {
          const {lat, lng} = this.activeFilterCriteria.filters.distance;
          this.cityToRestore.label = this.filter.sections.distance.label;
          this.cityToRestore.lat = lat;
          this.cityToRestore.lng = lng;
        }
        this.debouncedHandleFilterChange();
      }
    },
    setCity(city:ICity) {
      this.visibleCitySelect = false;
      if( this.isFilterTypeNearby ) {
        this.nearbyLocationHasChanged = this.formattedNearbySearchBar.placeholder !== city.location;
        this.$emit('on-nearby-location-updated', city);
      } else {
        this.activeFilterCriteria.filters.distance.lat = city.latitude;
        this.activeFilterCriteria.filters.distance.lng = city.longitude;
        this.activeFilterCriteria.filters.distance.location = city.locationLabel ?? city.location;
      }

      this.debouncedHandleFilterChange();
    },
    loadFilterModal() {
      this.showFilterModal = true;
      this.loadState = {showLoader: true, hideContent: true};
      if(this.currentFilterCriteria) {
        this.loadFilterContent();
      }
    },
    async loadFilterContent() {
      let filters;
      if(this.isFilterTypeHome) {
        filters = await FilterService.getHomeFilterByCityUnique(this.cityUnique, this.getFilterQueryParam(this.currentFilterCriteria.filters), this.cancelTokenSource);
      } else {
        filters = await FilterService.getNearbyFilterByPoint(this.nearbyLocationAsPoint, this.getFilterQueryParam(this.currentFilterCriteria.filters), this.cancelTokenSource);
      }
      this.filter = this.formatFilters(filters);
      this.loadState = {showLoader: false, hideContent: false};
    },
    onCurrentLocationClick() {
      this.showCitySelect();
    },
    async filterOnActiveCriteria(flow: FilterFlow, addCloseFilterCallback = true, preventSyncNearbyFilter = false, pushState = true) {
      if(flow === FilterFlow.Filter) {
        this.loadState.showLoader = true;
      }
      if(this.isFilterTypeHome && this.activeFilterCriteria.filters.sorting === 'distance') {
        this.navigateToNearby();
      }

      this.currentFilterCriteria = cloneDeep(this.activeFilterCriteria);
      let payload: SearchFilterEmit = {
        filterQueryParams: this.getFilterQueryParam(this.activeFilterCriteria.filters, flow),
        inspirationTag: this.getInspirationsTag(),
        closeFilterCallback: addCloseFilterCallback ? () => {
          if(this.showDrawerOrModal) {
            this.closeSelf();
            this.moveWhatsApp();
          }
          document.documentElement.scrollTop = 0;
        } : null
      }
      if(this.isFilterTypeNearby) {
        payload.nearbySearchLocation = this.formattedNearbySearchBar;

        $( '#search-bar').data('get-deals',false);
        const syncCategoriesEvent = new CustomEvent("nearby-filter-categories-changed", {
          detail: {
            selectedCategories: this.activeFilterCriteria.filters.categories.split(','),
          }});
        window.dispatchEvent(syncCategoriesEvent);
        if(!preventSyncNearbyFilter) {
          const event = new CustomEvent("sync-nearby-filter", {
            detail: {
              selectedCategories: this.activeFilterCriteria.filters.categories.split(','),
              selectedCity: this.nearbyLocationHasChanged ? {
                latitude: payload.nearbySearchLocation.lat,
                longitude: payload.nearbySearchLocation.long,
                location: payload.nearbySearchLocation.placeholder,
                locationText: $('.search-location .input-placeholder').text()
              } : null },
          });
          window.dispatchEvent(event);
        }
      }
      this.$emit('get-filter-search-results', pushState, payload );

      /* Some jQuery bullshit to manipulate te DOM */
      if(flow !== undefined) {
        const categoriesAsArray = this.currentFilterCriteria.filters.categories.split(',');
        if(this.isFilterTypeHome) {
          const $tagCloud = (<any>$( '#lastMinuteBox' ).find( '.tagcloud-container' ) ).tagCloud();
          if( $tagCloud ) {
            $tagCloud.getActiveItem().each( function ( this: any) {
              $tagCloud.setStyleToDefault( $( this ).removeClass('active') );
            } );

            const categories = this.activeFilterCriteria.filters.categories.split(',');
            let currentCategory: any;
            categories.forEach(function (category: string) {
              currentCategory = $tagCloud.getItemByDataCategory(category);
              $tagCloud.setStyleToActive(currentCategory, true, true);
            });

            if (currentCategory !== null) {
              $tagCloud.scrollItemIntoView(!sdViewport.isMobile() ? currentCategory!.first() : currentCategory!.last());
            }
          }
        }
        if( this.isFilterTypeNearby ) {
          const $tagCloud = $('nav[role="tagcloud"]');
          const $tagCloudDropdown = ($tagCloud as any).tagCloudDropdown();
          if (categoriesAsArray.length > 1) {
            $tagCloudDropdown.selectCustom(this.$t('36841.App_MultipleCategoryName').replace(':amount', categoriesAsArray.length));
            categoriesAsArray.forEach((category: string) => {
              $tagCloud.find('.dropdown li[data-identifier="' + category + '"]').addClass('active');
            })
          } else if (categoriesAsArray.length === 1) {
            $tagCloudDropdown.selectTag($tagCloud.find('.dropdown li[data-identifier="' + categoriesAsArray[0] + '"]'));
          } else {
            $tagCloudDropdown.selectTag('deal');
          }
          $('.sb-subtitle').text($tagCloud.find('.selected-tagcloud .placeholder').text());
        }
      }
      /* Until here :') */
    },
    getInspirationsTag(): string {
      const allCategory = !!this.filter.sections.categories.select_all_key ? this.filter.sections.categories.select_all_key : 'popular';
      const categories = this.activeFilterCriteria.filters.categories.split(',');
      if(categories.length > 1 || categories === allCategory) {
        return allCategory;
      }
      return categories;
    },
    getFilterQueryParam(filters: any, flow?:FilterFlow): string {
      let filterQueryParam = '';
      for (const [key, value] of Object.entries(filters)) {
        if(typeof value === 'object') {
          for(const [subkey, subvalue] of Object.entries(value)) {
            if(!subvalue) {
              continue;
            }
            filterQueryParam += `filter[${key}][${subkey}]=${encodeURIComponent(subvalue)}&`;
          }
        } else {
          filterQueryParam += `filter[${key}]=${encodeURIComponent(value as string | number)}&`;
        }
      }
      if(flow) {
        filterQueryParam += `flow=${flow}&`;
      }
      return filterQueryParam;
    },

    handleCategoryChange( evt: any ): void {
      const defaultCategory = 'popular';
      if( evt.detail.category === 'favorite' ) {
        this.$emit( 'show-favorites');
        return;
      }
      this.$emit( 'hide-favorites');
      this.activeFilterCriteria.filters.categories = evt.detail.category ?? defaultCategory;
      this.filterOnActiveCriteria( FilterFlow.Home,true, evt.detail.preventSyncNearbyFilter );
    },
    moveWhatsApp() {
      setTimeout(() => {
        $whatsappBox.move();
      }, 250);
    },
    emitBadgeUpdate() {
      this.$emit('on-badge-update', this.activeFilterCriteria.badge);
    },
    initNearbyMap(nearbyIdentifier:string) {
      $('#search-bar').data('original-searchbar').nearby_identifier = nearbyIdentifier;
      if($('#search-bar').data('init-map-nearby-listening')) {
        const event = new CustomEvent("init-nearby-map");
        window.dispatchEvent(event);
      } else {
        $('#search-bar').data('init-map-nearby-sent', true)
      }

    },
    async initFilter( evt: any ) {
      let filterObject = {} as any;
      if(this.isFilterTypeHome) {
        filterObject.distance = {};
      }
      let url = decodeURIComponent(window.location.search.substring( 1 ) );
      if( url !== '') {
        let urlParts = url.split('&');
        for (let u = 0; u < urlParts.length; u++) {
          let param = urlParts[u].split('=');
          if (param[0] === 'category') {
            param[0] = 'categories';
          }
          if (this.acceptedFilters.includes(param[0])) {
            switch (param[0]) {
              case 'price_range[min]':
                if (!filterObject['price_range']) {
                  filterObject['price_range'] = {};
                }
                filterObject['price_range']['min'] = param[1];
                break;
              case 'price_range[max]':
                if (!filterObject['price_range']) {
                  filterObject['price_range'] = {};
                }
                filterObject['price_range']['max'] = param[1];
                break;
              case 'distance[lat]':
                if (this.isFilterTypeNearby) {
                  this.nearbyLocation.lat = param[1];
                } else {
                  filterObject['distance']['lat'] = param[1];
                }
                break;
              case 'distance[lng]':
                if (this.isFilterTypeNearby) {
                  this.nearbyLocation.long = param[1];
                } else {
                  filterObject['distance']['lng'] = param[1];
                }
                break;
              case 'distance[value]':
                if (this.isFilterTypeNearby) {
                  filterObject['distance'] = param[1];
                } else {
                  filterObject['distance']['value'] = param[1];
                }
                break;
              case 'distance[location]':
                if (this.isFilterTypeNearby) {
                  filterObject['location'] = param[1];
                } else {
                  filterObject['distance']['location'] = param[1];
                }
                break;
              default:
                filterObject[param[0]] = param[1];
                break;
            }
          }

          if (this.isFilterTypeNearby && (this.nearbyLocation.lat || this.nearbyLocation.long)) {
            this.$emit('on-nearby-location-updated', {
              latitude: this.nearbyLocation.lat,
              longitude: this.nearbyLocation.long
            });
          }
        }
      }
      if( filterObject['distance'] && typeof filterObject['distance'] !== 'number' && Object.keys( filterObject['distance'] ).length === 0 ) {
        delete filterObject['distance'];
      }

      const reset = Object.keys( filterObject ).length > 0 || evt?.type === 'popstate';
      const filterQueryParam = reset ? this.getFilterQueryParam( filterObject, FilterFlow.Deeplink) : '';
      let currentFilterCriteria;
      if(this.isFilterTypeHome) {
        currentFilterCriteria = await FilterService.getHomeFilterCriteriaByCityUnique(this.cityUnique, reset, filterQueryParam);
      } else {
        currentFilterCriteria = await FilterService.getNearbyFilterCriteriaByPoint(this.nearbyLocationAsPoint, reset, filterQueryParam);
        const categoriesAsArray = currentFilterCriteria.filters.categories.split(',');
        const nearbyIdentifier = categoriesAsArray?.length === 1 ? categoriesAsArray[0] : 'deal';
        this.initNearbyMap(nearbyIdentifier);
      }
      this.currentFilterCriteria = currentFilterCriteria;
      this.activeFilterCriteria = currentFilterCriteria;

      if( this.showFilterModal ) {
        this.loadFilterContent();
      }

      this.emitBadgeUpdate();

      if( this.activeFilterCriteria.execute_search_call || evt?.type === 'popstate' ) {
        this.filterOnActiveCriteria(FilterFlow.Home, false, true, false);
      }
    }
  },
  async mounted() {
    this.initFilter();
    window.addEventListener('categorychange', this.handleCategoryChange);
    window.addEventListener('open-mobile-nearby-filter-click', this.onFilterButtonClick);
    document.getElementById('filter_button')?.addEventListener('click', this.onFilterButtonClick);
    window.addEventListener('reset-filter-outside-modal', this.resetFilterOutsideModal);
    window.addEventListener('popstate',this.initFilter);
  },
  beforeDestroy() {
    window.removeEventListener( 'categorychange', this.handleCategoryChange );
    window.removeEventListener('open-mobile-nearby-filter-click', this.onFilterButtonClick);
    document.getElementById('filter_button')?.removeEventListener('click', this.onFilterButtonClick);
    window.removeEventListener('reset-filter-outside-modal', this.resetFilterOutsideModal);
    window.removeEventListener('popstate',this.initFilter);
  },
});
