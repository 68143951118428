import ValueObject from "../../../../../frontend/ValueObject";
import {Price} from "./Price";
import {defaultCurrencyDTO} from "./dto/CurrencyDTO";

export interface ArrangementOptionProps {
    title: string,
    subtitle?: string,
    discount: any,
    sales: null,
    from_price: Price,
    price: Price,
    sold_out: boolean,
    alerts: any,
    is_purchasable: boolean,
    has_availability: boolean,
    is_not_available_on_date?: boolean
    multi_tab_name: string,
    pills?: any[],
    prices?: {
        discount_label: string,
        from_price: Price,
        price: Price
    },
    stats: {
        discount: string,
        sales: string
    },
    multi_deal_description: string,
    unique: string
}

export class ArrangementOption extends ValueObject<ArrangementOptionProps> {
    get title() {
        return this._props.title;
    }

    get subtitle() {
        return this._props.subtitle
    }

    get tab() {
        return this._props.multi_tab_name;
    }

    get fromPrice(): Price {
        return this._props.prices.from_price;
    }

    get price(): Price {
        return this._props.prices.price;
    }

    get stats(): any {
        return this._props.stats;
    }

    get pills(): any {
        return this._props.pills;
    }

    get unique(): any {
        return this._props.unique;
    }

    get alerts(): Array<any> {
        return this._props.alerts;
    }

    get isPurchasable() {
        return this._props.is_purchasable && this._props.has_availability !== false;
    }

    static create(props: any) {
        return new ArrangementOption(Object.assign(props, {
                subtitle: props.subtitle ?? null,
                prices: {
                    discount_label: props.stats.discount,
                    sales_label: props.stats.sales,
                    from_price: Price.create({
                        amount: props.prices?.from_price !== null ? props.prices?.from_price.amount / 100 : 0,
                        currency: props.prices?.from_price?.currency ?? defaultCurrencyDTO
                    }),
                    price: Price.create({
                        amount: props.prices?.price !== null ? props.prices?.price.amount / 100 : 0,
                        currency: props.prices?.price?.currency ?? defaultCurrencyDTO
                    }),
                },
                pills: props.pills?.map((pill: any) => {
                    return pill;
                })
            }
        ));
    }
}
