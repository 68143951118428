import ValueObject from "../../../../../frontend/ValueObject";
import {CurrencyDTO} from "./dto/CurrencyDTO";
import {PriceDTO} from "./dto/PricesDTO";
import {Money} from "./Money";

export interface IPrice {
    amount: number,
    currency?: CurrencyDTO,
    locale?: string,
}

export class Price extends ValueObject<IPrice> {
    get currency(): CurrencyDTO {
        return this._props.currency;
    }

    get amount(): number {
        return this._props.amount;
    }

    get locale(): string {
        return document.body.getAttribute('data-locale') ?? navigator.language;
    }

    get value(): string {
        return this.toHtml(true);
    }

    toString(): string {
        return (new Money(this, this.locale)).withSmallCents(false);
    }

    toHtml(bigger: boolean = false): string {
        let money: Money = new Money(this, this.locale);
        if (bigger) {
            return money.toString(bigger);
        }
        return money.withSmallCents();
    }

    static create(amountObject: any): Price {
        return new Price({
            amount: amountObject.amount,
            currency: amountObject.currency,
        });
    }

    static createPrice(price: PriceDTO): Price {
        return new Price({
            amount: price.amount / 100,
            currency: price.currency,
        });
    }
}
