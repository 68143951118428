


































import Vue, { PropType } from "vue";
import Day from "../../domain/Day";

export default Vue.extend( {
  name       : 'DayWithPrice',
  props      : {
    day    : {
      type     : Object as PropType<Day>,
      required : true
    },
    dayKey : {
      type     : Number,
      required : true
    }
  },
  computed   : {
    shouldShowBefore(): any {
      return (this.day.colors.background
              && this.day.colors.background_left
              || this.day.hoverColors?.background && this.day.hoverColors?.background_left)
             && (this.day.hoverToolTip && this.day.isHoverActive)
    },
    shouldShowAfter(): any {
      return this.day.colors.background && this.day.colors.background_right
    }
  },
  components : {
    ToolTip : () => import('./../ToolTip.vue')
  }
} );
